import React from "react";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

async function callApi(value) {
  const data = await fetch(`/api2/companys/`)
    .then((response) => response.json())

    //.then((response) => response.map(mapResponseToValuesAndLabels))
    .then((response) =>
      response.map((o) => ({
        value: o.orgNumber,
        label: o.name,
      }))
    )
    /*.then((res) => {
      //console.log(res);
    });*/
    .then((final) =>
      final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
    );

  return data;
}

export default function ProspectModal(props) {
  const [options, setOptions] = React.useState("{}");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [linkedinId, setLinkedinId] = React.useState("");
  const [firstEmployeeDate, setFirstEmployeeDate] = React.useState("");
  const postProspect = async () => {
    //e.preventDefault();
    //console.log(e);
    console.log("Prospectobject:");
    console.log(props.chosenProspect);

    try {
      let url = "/api2/prospects/";
      console.log(props.chosenProspect);
      if (props.chosenProspect.id != null) {
        url = url + props.chosenProspect.id;
      }
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props.chosenProspect),
      });

      // Check if the response is successful
      if (response.ok) {
        alert("Data sent successfully!");
        props.setShowModal(false);
      } else {
        const json = response.json().then((json) => {
          console.log(json);
          alert(json.errorMessage);
        });
        //alert(console.log(response.json()));
        props.setShowModal(false);
      }
    } catch (error) {
      alert("Error:" + error);
      props.setShowModal(false);
    }
  };

  return (
    <>
      {props.isActive ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.chosenProspect.firstName}{" "}
                    {props.chosenProspect.lastName}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <form onSubmit={postProspect}>
                    <div className="mb-1 flex flex-col gap-6">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="-mb-3"
                      >
                        First Name
                      </Typography>
                      <Input
                        size="md"
                        name="firstName"
                        value={props.chosenProspect.firstName}
                        onChange={(e) =>
                          props.chosenProspectSetter((ps) => ({
                            ...props.chosenProspect,
                            firstName: e.target.value,
                          }))
                        }
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />

                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="-mb-3"
                      >
                        Last Name
                      </Typography>

                      <Input
                        size="md"
                        name="lastName"
                        value={props.chosenProspect.lastName}
                        onChange={(e) =>
                          props.chosenProspectSetter((ps) => ({
                            ...props.chosenProspect,
                            lastName: e.target.value,
                          }))
                        }
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="-mb-3"
                      >
                        Company
                      </Typography>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={callApi}
                        value={
                          props.chosenProspect.currentEmployer
                            ? {
                                value:
                                  props.chosenProspect.currentEmployer
                                    .orgNumber,
                                label:
                                  props.chosenProspect.currentEmployer.name,
                              }
                            : null
                        }
                        onChange={(data) => {
                          console.log("setting chosen prospect");
                          console.log(data.value);
                          props.chosenProspectSetter((ps) => ({
                            ...props.chosenProspect,
                            currentEmployer: { orgNumber: data.value },
                          }));
                          console.log(props.chosenProspect);
                        }}
                        defaultOptions
                      />
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="-mb-3"
                      >
                        Url
                      </Typography>
                      <Input
                        name="linkedinId"
                        value={props.chosenProspect.linkedinId}
                        onChange={(e) =>
                          props.chosenProspectSetter((ps) => ({
                            ...props.chosenProspect,
                            linkedinId: e.target.value,
                          }))
                        }
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />

                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="-mb-3"
                      >
                        First job
                      </Typography>
                      <DatePicker
                        selected={props.chosenProspect.firstEmployeeDate}
                        onChange={(date) =>
                          props.chosenProspectSetter((ps) => ({
                            ...props.chosenProspect,
                            firstEmployeeDate: date,
                          }))
                        }
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      props.chosenProspectSetter({});
                      props.setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-green active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      postProspect();
                      props.setShowModal(false);
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import React, { useState, useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-light.css";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

export default function Events(props) {
  const [prospectId, setProspectId] = React.useState(0);
  const loadData = ({ skip, limit, sortInfo }) => {
    let url = "/api/events/" + prospectId;
    return fetch(url, { redirect: "manual" }).then((response) => {
      //const totalCount = response.headers.get("X-Total-Count");
      return response.json().then((data) => {
        return data;
        /*const res = data.map((p) => ({
          ...p,
          linkedinUrl: "https://linkedin.com",
        }));
        console.log(res);
  
        return { res, count: parseInt(totalCount) };*/
      });
    });
  };
  const dataSource = useCallback(loadData, []);

  const gridStyle = { height: 550 };

  const columns = [
    {
      name: "id",
      header: "Id",
      maxWidth: 100,
      defaultFlex: 1,
      type: "number",
    },
    {
      name: "type",
      header: "Type",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    { name: "message", header: "Message", minWidth: 50, defaultFlex: 2 },
    {
      name: "time",
      header: "Time",
      minWidth: 50,
      defaultFlex: 2,
      editable: true,
    },
  ];

  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log(value + ":" + columnId + ":" + rowId);
      //const data = [...dataSource];
      //data[rowId][columnId] = value;
      //console.log(data);
      //setDataSource(data);
    },
    [dataSource]
  );

  //e.preventDefault();
  //console.log(e);

  return (
    <>
      {props.isActive ? (
        <>
          <ReactDataGrid
            theme="default-light"
            idProperty="id"
            columns={columns}
            dataSource={dataSource}
            style={gridStyle}
            stickyHeader={true}
            onEditComplete={onEditComplete}
            //onRowDoubleClick={onRowDoubleClick}
            //onRowClick={onRowClick}
            //editable={true}
            //onEditComplete={onEditComplete}
          />
          {/*footer*/}

          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              props.setShowEvents(false);
            }}
          >
            Close
          </button>
          <button
            className="bg-emerald-500 text-green active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              props.setShowEvents(false);
            }}
          >
            Save Changes
          </button>
        </>
      ) : null}
    </>
  );
}

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";
import React, { useState, useCallback, useEffect } from "react";
import ProspectModal from "./ProspectModal";
import Events from "./Events";

import "./index.css";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import Navbar from "./Navbar";

function renderState() {
  return "Uncontacted";
}

const url = "/api2/prospects/";

const gridStyle = { minHeight: 550, height: "90%" };

async function fetchProspect(id, setter) {
  const data = await fetch(`/api2/prospects/${id}`, { redirect: "manual" })
    .then((response) => response.json())

    //.then((response) => response.map(mapResponseToValuesAndLabels))
    .then((response) => {
      console.log(response);
      return response;
    });
  setter(data);
}

const loadData = ({ skip, limit, sortInfo }) => {
  return fetch(url, { redirect: "manual" }).then((response) => {
    //const totalCount = response.headers.get("X-Total-Count");
    return response.json().then((data) => {
      return data;
      /*const res = data.map((p) => ({
        ...p,
        linkedinUrl: "https://linkedin.com",
      }));
      console.log(res);

      return { res, count: parseInt(totalCount) };*/
    });
  });
};

function App() {
  const columns = [
    {
      name: "id",
      header: "Id",
      maxWidth: 50,
      defaultFlex: 1,
      render: ({ value }) => (
        <>
          <span onClick={() => onRowEdit(value)}>{value}</span>
        </>
      ),
      type: "number",
    },
    {
      name: "linkedinId",
      header: "L-Id",
      minWidth: 220,
      maxWidth: 800,
      defaultFlex: 1,
      render: ({ value }) => (
        <a
          target="_new"
          href={`https://linkedin.com/in/${value}`}
          style={{ color: "#fffff" }}
        >
          <span style={{ color: "#fff" }}>{value}</span>
        </a>
      ),
    },
    {
      name: "state",
      header: "State",
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ value }) => (
        <>
          <span onClick={() => onEvents(value)}>State</span>
        </>
      ),
    },
    {
      name: "firstName",
      header: "First",
      minWidth: 30,
      maxWidth: 300,
      defaultFlex: 2,
    },
    { name: "lastName", header: "Last", minWidth: 50, defaultFlex: 2 },
    {
      name: "firstEmployeeDate",
      header: "First employee",
      minWidth: 50,
      defaultFlex: 1,
      render: ({ value }) => <>{new Date(value).toDateString()}</>,
    },
    {
      name: "currentEmployer",
      header: "Employer",
      minWidth: 50,
      defaultFlex: 1,
      render: ({ value }) => <>{value ? value.name : ""}</>,
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [chosenProspect, setChosenProspect] = useState({});

  const dataSource = useCallback(loadData, []);

  const onRowClick = useCallback((rowProps, event) => {
    console.log("Single click:" + rowProps.data.id);
    fetchProspect(rowProps.data.id, setChosenProspect);
    setShowModal(true);
  }, []);

  function onRowEdit(prospectId) {
    fetchProspect(prospectId, setChosenProspect);
    setShowModal(true);
  }

  function onEvents(prospectId) {
    console.log("show events");
    //fetchProspect(prospectId, setChosenProspect);
    setShowEvents(true);
  }

  const onRowDoubleClick = useCallback((event, rowProps) => {
    console.log("Double clicked on:" + rowProps.data.id);
    //fetchProspect(rowProps.rowIndex, setChosenProspect);
    //setShowModal(true);
  }, []);

  /*const onCellClick = useCallback((event, cellProps) => {
    console.log("cell click:" + rowProps.data.id);
    
  });*/

  return (
    <>
      <Navbar
        chosenProspect={chosenProspect}
        setChosenProspect={setChosenProspect}
        setShowModal={setShowModal}
      />

      <ProspectModal
        isActive={showModal}
        chosenProspect={chosenProspect}
        chosenProspectSetter={setChosenProspect}
        setShowModal={setShowModal}
      />

      <Events
        isActive={showEvents}
        setShowEvents={setShowEvents}
        chosenProspect={chosenProspect}
      />

      <ReactDataGrid
        theme="default-dark"
        idProperty="id"
        columns={columns}
        dataSource={dataSource}
        style={gridStyle}
        stickyHeader={true}
        //onRowDoubleClick={onRowDoubleClick}
        //onRowClick={onRowClick}
        //editable={true}
        //onEditComplete={onEditComplete}
      />
    </>
  );
}

export default App;
